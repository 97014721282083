import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import CopywritingPage from "pages/CopywritingPage";
import ResearchPage from "pages/ResearchPage";
import ResearchResultsPage from "pages/ResearchResultsPage";
import DesignPage from "pages/DesignPage";
import LMGPage from "pages/LMGPage";
import SettingsPage from "pages/SettingsPage";
import SupportPage from "pages/SupportPage";
import PromptPage from "pages/PromptPage";
import { UserProvider, useUserContext } from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "components/ProtectedRoute";

import logo from "assets/images/logo.svg";
import userAvatar from "assets/images/userAvatar.png";
import homeIcon from "assets/images/home-line.svg";
import createIcon from "assets/images/edit-04.svg";
import researchIcon from "assets/images/search-refraction.svg";
import searchIcon from "assets/images/search-refraction.svg";
import designIcon from "assets/images/palette.svg";
import lmgIcon from "assets/images/linkedin-icon-grey.svg";
import AdminPanel from "pages/AdminPanel";
import HistoryPage from "pages/HistoryPage";
import DeletionPage from "pages/DeletionPage";
import SentimentAnalysisPage from "pages/SentimentAnalysisPage";
function AppContent() {
  const navigate = useNavigate();
  const { userData, avatarUrl } = useUserContext();
  const { logout } = useAuth0();

  const modules = userData?.preferences?.modules || [];

  const allNavItems = [
    { icon: homeIcon, label: "Home", path: "/home", moduleId: "home" },
    { icon: createIcon, label: "Copywriting", path: "/copywriting", moduleId: "COPYWRITING", tooltip: "Tool for generating copywriting content for various platforms matching your brand voice.", 
      subItems: [
      { icon: createIcon, label: "Prompt Templates", path: "/prompt/copywriting", moduleId: "COPYWRITING" },
      { icon: researchIcon, label: "History", path: "/history/copywriting", moduleId: "COPYWRITING" },
    ] },
    { icon: searchIcon, label: "Sentiment Analysis", path: "/sentiment-analysis", moduleId: "SENTIMENT_ANALYSIS", tooltip: "Tool for analyzing the sentiment of a given text.",
      subItems: [
        { icon: researchIcon, label: "History", path: "/history/sentiment-analysis", moduleId: "SENTIMENT_ANALYSIS" },
      ]
     },
    { icon: researchIcon, label: "Research", path: "/research", moduleId: "research" },
    { icon: designIcon, label: "Design", path: "/design", moduleId: "design" },
    { icon: lmgIcon, label: "Cold Outreach", path: "/lmg", moduleId: "LMG", tooltip: "Tool for generating highly personalized cold outreach messages on LinkedIn." },
  ];

  const sidebarProps = {
    logo: logo,
    navItems: [
      ...allNavItems.filter(item => 
        item.moduleId === "home" || modules.includes(item.moduleId)
      ),
    ],
    userProfile: {
      avatar: avatarUrl || userAvatar,
      name: `${userData?.first_name || "Example"} ${userData?.last_name || "User"}`,
      email: userData?.email || "example@user.com",
      onLogout: () => {
        console.log("Logout clicked");
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
        navigate("/login");
      },
    },
    onSearchInputChange: (value: string) => {
      console.log("Search input changed:", value);
    },
    onSearchSubmit: (value: string) => {
      console.log("Search submitted:", value);
    },
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route element={
        <ProtectedRoute>
          <Layout sidebarProps={sidebarProps} />
        </ProtectedRoute>
      }>
        <Route path="/home" element={<HomePage />} />
        <Route path="/copywriting" element={<CopywritingPage />} />
        <Route path="/sentiment-analysis" element={<SentimentAnalysisPage />} />
        <Route path="/history/sentiment-analysis" element={<HistoryPage tool="SENTIMENT_ANALYSIS" />} />
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/research/results" element={<ResearchResultsPage />} />
        <Route path="/design" element={<DesignPage />} />
        <Route path="/lmg" element={<LMGPage />} />
        <Route path="/prompt/lmg" element={<PromptPage tool="LMG" />} />
        <Route path="/prompt/copywriting" element={<PromptPage tool="COPYWRITING" />} />
        <Route path="/history/copywriting" element={<HistoryPage tool="COPYWRITING" />} />
        <Route path="/deletion" element={<DeletionPage />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </Router>
  );
}

export default App;
