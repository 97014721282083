import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import Button from 'components/Forms/Button';
import { useNavigate } from 'react-router-dom';
import arrowRight from 'assets/images/arrow-up-right.svg';
import AlertDialog from 'components/Common/AlertDialog';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CustomizedSnackbars from 'components/Forms/Snackbar';

const DeleteSection = () => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);
    const onDelete = () => {
        setShowAlert(true);
    }
    
    const onAgree = async () => {
        const token = await getAccessTokenSilently();
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(backend_api_url + `/files/delete`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const response_posts = await fetch(backend_api_url + `/copywriter/posts`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok && response_posts.ok) {
            setSnackbarMessage("All data deleted");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('success');
        }
        setShowAlert(false);
    }
    
    const onDisagree = () => {
        setShowAlert(false);
    }

    return (
        <>
            {showAlert && (
                <AlertDialog 
                    title="Erase all data" 
                    description="Are you sure you want to erase all data from the app? This action cannot be undone." 
                    agreeText="Yes" 
                    disagreeText="No" 
                    onAgree={onAgree} 
                    onDisagree={onDisagree}
                    open={showAlert}
                />
            )}
            <SectionWrapper title="Deletion" description="Delete files you don't need anymore.">
                <FormContainer>
                    <FormLabel label="Navigation" description="Go to the selective deletion page." />
                    <FormContent>
                    <div className="flex flex-wrap gap-8">
                        <Button 
                        variant="primary" 
                        type="button" 
                        size="md" 
                        onClick={() => navigate(`/deletion`)}
                        >
                        <img 
                        src={arrowRight} 
                        alt="Save" 
                        className="w-5 h-5" 
                        />
                        </Button>
                    </div>
                    </FormContent>
                </FormContainer>
                <FormContainer>
                    <FormLabel label="Erase all data" description="Delete all data from the app." />
                    <FormContent>
                        <div className="flex flex-wrap gap-8">
                            <Button variant="primary" type="button" size="md" onClick={onDelete}>Delete</Button>
                        </div>
                    </FormContent>
                </FormContainer>
            </SectionWrapper>
            {snackbarMessage && (
                <CustomizedSnackbars 
                    message={snackbarMessage}
                    type={snackbarType}
                    key={snackbarKey}
                />
            )}
        </>
    )
}

export default DeleteSection;