import React, { useState } from 'react';
import chevronDown from 'assets/images/chevron-down.svg';
import chevronUp from 'assets/images/chevron-up.svg';
import { useAuth0} from '@auth0/auth0-react';
import Button from 'components/Forms/Button';
import Spinner from 'components/Forms/Spinner';
import sparkleIcon from 'assets/images/star-06-white.svg';
import placeholderIcon from 'assets/images/x-close.svg';
import fileIcon from 'assets/images/file-icons/doc.svg';
import audioIcon from 'assets/images/record-icon-violett.svg';
import editIcon from 'assets/images/edit-04.svg';
import CustomizedSnackbars from 'components/Forms/Snackbar';
import CopywriterPost from 'contexts/CopywriterPost';
import { useNavigate } from 'react-router-dom';
import deleteIcon from 'assets/images/delete.svg';
const PostHistoryForm: React.FC<CopywriterPost> = ({
  uuid,
  created_at,
  input,
  output,
  settings
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

const handleSubmit = async () => {
  setLoading(true);
  sessionStorage.setItem('copywriting_inspiration', input.inspiration.text);
  sessionStorage.setItem('copywriting_platform', settings.target_platform);
  sessionStorage.setItem('copywriting_template', settings.style_template_id);
  sessionStorage.setItem('copywriting_inputType', 'text');
  sessionStorage.setItem('copywriting_isGenerated', 'true');
  sessionStorage.setItem('copywriting_postId', uuid);
  sessionStorage.setItem('copywriting_output', JSON.stringify({
    text: output.history[output.history.length - 1].text,
    html: `<p>${output.history[output.history.length - 1].text}</p>`
  }));
  navigate(`/copywriting`);
  setLoading(false);
}

const handleDelete = async () => {
  setDeleteLoading(true);

    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(backend_api_url + `/copywriter/posts/${uuid}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
    });

    setDeleteLoading(false);
    window.location.reload();
}

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-6">
          <div className="flex overflow-hidden relative flex-row gap-2 justify-center items-center self-stretch my-auto w-auto">
                {input.file && input.file.is_serialized ? (
                    <img src={fileIcon} alt="File" className="w-5 h-5" />
                ) : (
                    <img src={placeholderIcon} alt="Placeholder" className="w-5 h-5" />
                )}
                {input.inspiration && input.inspiration.audio_id ? (
                    <img src={audioIcon} alt="Audio" className="w-5 h-5" />
                ) : (
                    <img src={editIcon} alt="Edit" className="w-5 h-5" />
                )}
          </div>
          <div className="flex gap-2 text-sm">
            <span className="font-semibold leading-5 text-slate-700">
              {output.subject}
            </span>
          </div>
        </div>
        <button
          className="flex overflow-hidden gap-2 justify-center items-center p-2 rounded-lg hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label={isExpanded ? "Collapse" : "Expand"}
        >
          <img
            loading="lazy"
            src={isExpanded ? chevronUp : chevronDown}
            alt={isExpanded ? "Collapse" : "Expand"}
            className="w-5 h-5"
          />
        </button>
      </div>
      
      {isExpanded && (
        <div className="flex items-start justify-between gap-4 p-4 border-t border-gray-200">
          <div className="flex flex-col gap-4">
            <span className="leading-5 text-slate-500">
              Created at: {new Date(created_at).toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit', 
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
          </div>
          <div className="flex gap-2">
            <Button 
                variant="primary" 
                type="button" 
                size="md" 
                onClick={handleDelete} 
                disabled={deleteLoading}
            >
            {deleteLoading ? (
                <Spinner />
              ) : (
                <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
              )}
            </Button>
            <Button 
                variant="primary" 
                type="button" 
                size="md" 
                onClick={handleSubmit} 
                disabled={loading}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <img 
                    src={sparkleIcon} 
                    alt="Continue" 
                    className="w-5 h-5" 
                    />
                )}
                {loading ? `${'Preparing...'}` : `${'Continue'}`} 
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostHistoryForm;
