import React, { useState } from "react";
import SectionWrapper from "components/Common/SectionWrapper";
import SentimentTable from "components/Forms/SentimentTable";



interface OutputSectionProps {
  output: string;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  pageRef: React.RefObject<HTMLDivElement>;
}

const OutputSection: React.FC<OutputSectionProps> = ({ 
  output,
  textareaRef,
  pageRef
}) => {

  return (
    <SectionWrapper
      title="Output"
      description="See in detail which issues our AI has found in the text."
    >
        <SentimentTable 
          data={output} 
          inputRef={textareaRef}
          pageRef={pageRef}
        />
    </SectionWrapper>
  );
};

export default OutputSection;
