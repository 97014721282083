import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface Issue {
  textstelle: string;
  type: string;
  grund: string;
  verbesserung: string;
}

interface Column {
  id: keyof Issue;
  label: string;
  minWidth?: number;
  width?: string;
}

const columns: readonly Column[] = [
  { id: 'textstelle', label: 'Textstelle', minWidth: 170 },
  { id: 'type', label: 'Typ', minWidth: 100 },
  { id: 'grund', label: 'Grund', minWidth: 170, width: '30%' },
  { id: 'verbesserung', label: 'Verbesserungsvorschlag', minWidth: 170, width: '30%' },
];

interface SentimentData {
  sentiment: string;
  issues: Issue[];
}

interface SentimentTableProps {
  data: string;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  pageRef: React.RefObject<HTMLDivElement>;
}

const SentimentTable: React.FC<SentimentTableProps> = ({ data, inputRef, pageRef }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const parsedData = JSON.parse(data);

  const handleTextClick = (text: string) => {
    if (inputRef.current) {
      const content = inputRef.current.value;
      const startIndex = content.indexOf(text);
      if (startIndex !== -1) {
        // Scroll zur Seitenanfang mit der pageRef
        if (pageRef.current) {
          pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setTimeout(() => {
          if (inputRef.current) {
            // Text selektieren
            inputRef.current.focus();
            inputRef.current.setSelectionRange(startIndex, startIndex + text.length);
            
            // Scroll zur Selektion
            const textArea = inputRef.current;
            const lineHeight = parseInt(window.getComputedStyle(textArea).lineHeight);
            const selectionTop = textArea.selectionStart / textArea.value.length * textArea.scrollHeight;
            const scrollPosition = selectionTop - textArea.clientHeight / 2;
            textArea.scrollTop = scrollPosition;
          }
        }, 300);
      }
    }
  };

  if (!parsedData) {
    return <div>Keine gültigen Daten verfügbar</div>;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div ref={pageRef}>
      <Typography variant="h6" gutterBottom>
        Sentiment-Analyse: {parsedData.sentiment}
      </Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth, width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedData.issues
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Issue, index: number) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => (
                      <TableCell 
                        key={column.id}
                        onClick={() => column.id === 'textstelle' ? handleTextClick(row[column.id]) : null}
                        sx={column.id === 'textstelle' ? { cursor: 'pointer' } : undefined}
                      >
                        {column.id === 'textstelle' ? `"${row[column.id]}"` : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={parsedData.issues.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default SentimentTable;
