import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
  title: string;
  description: string;
  agreeText: string;
  disagreeText: string;
  onAgree: () => void;
  onDisagree: () => void;
  open: boolean;
}

export default function AlertDialog({ title, description, agreeText, disagreeText, onAgree, onDisagree, open }: AlertDialogProps) {
  const handleAgree = () => {
    onAgree();
  };

  const handleDisagree = () => {
    onDisagree();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree}>{disagreeText}</Button>
        <Button onClick={handleAgree} autoFocus>
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}