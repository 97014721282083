import Header from 'components/Common/Header';
import TemplateSection from 'components/Setting/TemplateSection';
import ProfileSection from 'components/Setting/ProfileSection';
import LLMSection from 'components/Setting/LLMSelection';
import { useUserContext } from 'contexts/UserContext';
import DeleteSection from 'components/Setting/DeleteSection';

const SettingsPage: React.FC = () => {
  const { userData } = useUserContext();

  return (
    <>
      <Header title="Settings" />
      {(!userData?.first_name || !userData?.last_name) && (
        <div className="flex flex-col gap-4 bg-red-50 p-4 rounded-lg border border-red-100">
          <div className="text-2xl font-bold">Welcome to DECAID Forge!</div>
          <div className="text-xl font-semibold">Please enter your first and last name before using the app.</div>
        </div>
      )}
      <ProfileSection />
      <TemplateSection />
      <LLMSection />
      <DeleteSection />
    </> 
  );
};

export default SettingsPage;