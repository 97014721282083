import React, { useState } from "react";
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormLabel from "components/Forms/FormLabel";
import FormContent from "components/Forms/FormContent";
import TextField from "components/Forms/TextField";
import FileUpload from "components/Forms/FileUpload";
import { useAuth0 } from "@auth0/auth0-react";
import Toggle from "components/Forms/Toggle";

interface InputSectionProps {
  onTextChange: (value: string) => void;
  text: string;
  setFile: (file_id: string) => void;
  inputType: "text" | "file";
  setInputType: (input_type: "text" | "file") => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const InputSection: React.FC<InputSectionProps> = ({ 
  onTextChange, 
  text, 
  setFile, 
  inputType,
  setInputType,
  textareaRef
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const handlePromptInputTypeSelect = (value: string) => {
    setInputType(value as "text" | "file");
  };

  const handleFileUploaded = (file: File) => {
    console.log(`File uploaded: ${file.name}`);
    upload(file);
  };

  const upload = async (file: File | Blob) => {
    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    
    const formData = new FormData();
    formData.append("file", file);
    
    const url = new URL(backend_api_url + '/files/upload');
      url.searchParams.append("container_name", "sentimentanalysisfileupload");
      
    try {
        const response = await fetch(url.toString(), {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        
        if (!response.ok) {
            throw new Error(`Upload failed: ${await response.text()}`);
        }

        const file_id = await response.json();
        console.log("Upload successful, file_id:", file_id);
        
        // Setze je nach Dateityp den entsprechenden State
        if (file instanceof File) {
            setFile(file_id);
        }
        
        return file_id;
    } catch (error) {
        console.error("Upload error:", error);
        throw error;
    }
  };


  return (
    <SectionWrapper
      title="Input"
      description="Analyze text or upload files to analyze."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Text"
          description="Text you want to analyze"
        />
                <FormContent>
          <Toggle
            options={[
              { caption: "Text", value: "text" },
              { caption: "File", value: "file" },
            ]}
            onSelect={handlePromptInputTypeSelect}
          />
          {inputType === "text" ? (
            <TextField
            name="prompt"
            ariaLabel="Prompt"
            placeholder="Donald Trump is an orange clown."
            showmaxChars={false}
            onChange={onTextChange}
            initialValue={text}
            fixedHeight={true}
            inputRef={textareaRef}
            />
          ) : (
            <FileUpload
            allowedFormats={["pdf", "txt", "docx", "doc"]}
            formatMessage="PDF, TXT, DOCX, DOC"
            onFileUploaded={handleFileUploaded}
          />
          )}
        </FormContent>
      </FormContainer>
    </SectionWrapper>
  );
};

export default InputSection;
