import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'components/Forms/Spinner';
import CustomizedSnackbars from 'components/Forms/Snackbar';
import deleteIcon from 'assets/images/delete-grey.svg';

interface FileFormProps {
  name : string;
  id : string;
  onDelete?: () => void;
}

const FileForm: React.FC<FileFormProps> = ({
  name, 
  id,
  onDelete
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);



const handleDelete = async () => {
    setLoading(true);

    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(backend_api_url + `/files/delete/${id}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
    });
    if (response.ok) {
      setSnackbarMessage("File deleted");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('success');
      if (onDelete) {
        onDelete();
      }
    }
    setLoading(false);
}

  return (
    <div className="bg-white rounded-lg p-4 shadow-md w-full max-w-sm">
      <div className="flex flex-col items-center gap-3">
        <span className="text-sm font-medium text-center break-words w-full">{name}</span>
        <button
          onClick={handleDelete}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          disabled={loading}
        >
          {loading ? (
            <Spinner />
          ) : (
            <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
          )}
        </button>
      </div>

      {snackbarMessage && (
        <CustomizedSnackbars 
          message={snackbarMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </div>
  );
};

export default FileForm;
