import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import Toggle from "components/Forms/Toggle";
import DropDown, { DropdownItem } from "components/Forms/DropDown";
import { useUserContext } from 'contexts/UserContext';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CustomizedSnackbars from 'components/Forms/Snackbar';

interface LLMModel {
    name: string;
}

interface LLMModelsResponse {
    Models: LLMModel[];
}

const LLMSection = () => {
    const { userData, setUserData } = useUserContext();
    const { getAccessTokenSilently } = useAuth0();
    const [llmProvider, setLLMProvider] = useState(userData?.preferences.llm_provider);
    const [llmModel, setLLMModel] = useState(userData?.preferences.llm_model);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);

    const [openaiModels, setOpenaiModels] = useState<DropdownItem[]>([]);
    const [anthropicModels, setAnthropicModels] = useState<DropdownItem[]>([]);

    useEffect(() => {
        const fetchModels = async () => {
            const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
            const response = await fetch(`${backendUrl}/llm/models`);
            const data: LLMModelsResponse = await response.json();
            
            const openai = data.Models
                .filter(model => model.name.startsWith('gpt') || model.name.startsWith('o'))
                .map(model => ({ label: model.name, value: model.name }));
                
            const anthropic = data.Models
                .filter(model => model.name.startsWith('claude'))
                .map(model => ({ label: model.name, value: model.name }));
                
            setOpenaiModels(openai);
            setAnthropicModels(anthropic);
        };
        
        fetchModels();
    }, []);

    const onSelectLLMProvider = async (provider: string) => {
        const newModel = provider === "openai" ? openaiModels[0].value : anthropicModels[0].value;
        setLLMProvider(provider);
        setLLMModel(newModel);
        
        const token = await getAccessTokenSilently();
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(`${backendUrl}/users/update/${userData?.uuid}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                preferences: {
                    modules: userData?.preferences.modules,
                    llm_provider: provider,
                    llm_model: newModel
                }
            })
        });
        
        if (response.ok && userData) {
            setUserData(prev => prev ? {
                ...prev,
                preferences: {
                    ...prev.preferences,
                    llm_provider: provider,
                    llm_model: newModel
                }
            } : null);
            setSnackbarMessage('Updated successfully');
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('success');
        }
    }

    const onSelectLLMModel = async (item: DropdownItem) => {
        setLLMModel(item.value);
        const token = await getAccessTokenSilently();
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(`${backendUrl}/users/update/${userData?.uuid}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                preferences: {
                    modules: userData?.preferences.modules,
                    llm_provider: llmProvider,
                    llm_model: item.value
                }
            })
        });

        if (response.ok && userData) {
            setUserData(prev => prev ? {
                ...prev,
                preferences: {
                    ...prev.preferences,
                    llm_model: item.value
                }
            } : null);
            setSnackbarMessage('Updated successfully');
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('success');
        }
    }

    return (
        <SectionWrapper title="LLM Selection" description="Choose which LLM provider and model you want to use.">
            <FormContainer>
                <FormLabel label="LLM Provider" description="Choose the LLM provider you want to use." />
                <FormContent>
                <Toggle
                    options={[
                        { caption: "OpenAI", value: "openai" },
                        { caption: "Anthropic", value: "anthropic" },
                    ]}
                    onSelect={onSelectLLMProvider}
                    selected={llmProvider === "openai" ? 0 : 1}
                />
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="LLM Model" description="Choose the LLM model you want to use." />
                <FormContent>
                    <DropDown
                        items={llmProvider === "openai" ? openaiModels : anthropicModels}
                        onSelect={onSelectLLMModel}
                        defaultSelected={llmModel}
                    />
                </FormContent>
            </FormContainer>
            {snackbarMessage && (   
                <CustomizedSnackbars
                    message={snackbarMessage}
                    key={snackbarKey}
                    type={snackbarType}
                />
            )}
        </SectionWrapper>
    )
}

export default LLMSection;