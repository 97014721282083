import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface UserData {
    uuid: string;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    avatar: string;
    preferences: {
      modules: string[];
      llm_provider: string;
      llm_model: string;
    };
  // Weitere Felder hier definieren, falls benötigt
}

interface UserContextProps {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  avatarUrl: string | null;
  setAvatarUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userData, setUserData] = useState<UserData | null>(() => {
    // Hole `userData` aus `sessionStorage`, falls vorhanden
    const storedUserData = sessionStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : null;
  });
  const [avatarUrl, setAvatarUrl] = useState<string | null>(() => {
    // Hole avatarUrl aus sessionStorage, falls vorhanden
    return sessionStorage.getItem("avatarUrl");
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(backend_api_url + '/users/login', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setUserData(data);

        // Speichere userData in `sessionStorage`
        sessionStorage.setItem("userData", JSON.stringify(data));

        const response2 = await fetch(`${backend_api_url}/files/retrieve/${data.avatar}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response2.ok) throw new Error(`HTTP error! Status: ${response2.status}`);
        const blob = await response2.blob();
        // Konvertiere Blob zu Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setAvatarUrl(base64data);
          // Speichere Base64-String in sessionStorage
          sessionStorage.setItem("avatarUrl", base64data);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Fehler beim Abrufen von userData:", error);
      }
    };
    

    // API-Aufruf nur, wenn Auth0 geladen ist und `isAuthenticated` true ist
    if (!isLoading && isAuthenticated && !userData) {
      fetchUserData();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, userData]);

  return (
    <UserContext.Provider value={{ userData, setUserData, avatarUrl, setAvatarUrl }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext muss innerhalb von UserProvider verwendet werden");
  }
  return context;
};

