function convertStatusMessage(
    status: string,
    llm_provider: string,
    statusMessage?: string,
): { progress: number, status: string } {
    switch (status) {
        case 'pending':
            return { progress: 0, status: 'Checking where the Brandmauer stands...' };
        case 'error':
            return { progress: 0, status: 'An error occurred. Please try again.' };
        case 'completed':
            return { progress: 100, status: 'Coffee is ready!' };
        case 'processing':
            switch (statusMessage) {
                case 'serializing':
                    return { progress: 10, status: 'Brewing some coffee...' };
                case 'generating':
                    return { progress: 25, status: `Asking ${llm_provider} how they like their coffee...` };
                case 'subjecting':
                    return { progress: 75, status: 'Naming our coffee creation...' };
                default:
                    return { progress: 50, status: 'Processing...' };
            }
        default:
            return { progress: 0, status: 'Unknown status' };
    }
}

export { convertStatusMessage };
