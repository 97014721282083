import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import TextField from 'components/Forms/TextField';
import { useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import Button from 'components/Forms/Button';
import Spinner from 'components/Forms/Spinner';
import saveIcon from 'assets/images/save.svg';
import { useAuth0 } from '@auth0/auth0-react';
import CustomizedSnackbars from 'components/Forms/Snackbar';
import AvatarUpload from 'components/Forms/AvatarUpload';


const ProfileSection = () => {
    const { userData, setUserData, avatarUrl, setAvatarUrl } = useUserContext();
    const [formData, setFormData] = useState({
      uuid: userData?.uuid || '',
      first_name: userData?.first_name || '',
      last_name: userData?.last_name || '',
      email: userData?.email || '',
      avatar: userData?.avatar || 'default.png',
      preferences: userData?.preferences || { modules: [], llm_provider: '', llm_model: '' },
      role: userData?.role || 'user',
    });
    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);


    const handleInputChange = (name: string) => (value: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        console.log('Form data:', formData);
        console.log('User data:', userData);
        if (formData.first_name === '' || formData.last_name === '') {
            setSnackbarMessage("Please enter your first and last name");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('error');
            setLoading(false);
            return;
        }
        const token = await getAccessTokenSilently();
        const user_id = userData?.uuid;
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(backend_api_url + `/users/update/${user_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
        });
        if (response.ok) {
        // Update userData in session storage
        const updatedUserData = { ...userData, ...formData };
        sessionStorage.setItem('userData', JSON.stringify(updatedUserData));
        // Update userData in context
        setUserData(updatedUserData);
        setSnackbarMessage("Profile updated");
        setSnackbarKey(prev => prev + 1);
        setSnackbarType('success');
        const response2 = await fetch(`${backend_api_url}/files/retrieve/${formData.avatar}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response2.ok) throw new Error(`HTTP error! Status: ${response2.status}`);
          const blob = await response2.blob();
          // Konvertiere Blob zu Base64
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            // Speichere Base64-String in sessionStorage
            sessionStorage.setItem("avatarUrl", base64data);
            setAvatarUrl(base64data);
          };
          reader.readAsDataURL(blob);
          window.location.reload();
        }
        setLoading(false);
    }

    const handleAvatarUpload = async (file: File | Blob) => {
        const token = await getAccessTokenSilently();
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        
        const formData = new FormData();
        formData.append("file", file);
        
        const url = new URL(backend_api_url + '/files/upload');
        url.searchParams.append("container_name", "avatars");
        
        try {
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            
            if (!response.ok) {
                throw new Error(`Upload failed: ${await response.text()}`);
            }

            const file_id = await response.json();
            console.log("Upload successful, file_id:", file_id);
            
            // Setze je nach Dateityp den entsprechenden State
            setFormData(prev => ({
                ...prev,
                avatar: file_id
            }));
            
            return file_id;
        } catch (error) {
            console.error("Upload error:", error);
            throw error;
        }
    };
        

    return (
            <SectionWrapper title="Profile" description="Manage your profile information.">
            <FormContainer>
                <FormLabel label="Avatar" description="Click your avatar to change it." />
                <FormContent>
                    <AvatarUpload onAvatarUpload={handleAvatarUpload} avatarUrl={avatarUrl || ''} />
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Name" description="Input your first and last name." />
                <FormContent>
                    <TextField name="first_name" 
                    ariaLabel="First Name" 
                    placeholder="Enter your first name" 
                    initialValue={formData.first_name}
                    showmaxChars={false}
                    onChange={handleInputChange("first_name")}
                    />
                    <TextField name="last_name" 
                    ariaLabel="Last Name" 
                    placeholder="Enter your last name" 
                    initialValue={formData.last_name}
                    showmaxChars={false}
                    onChange={handleInputChange("last_name")}
                    />
                    
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Save" description="Save your changes." />
                <FormContent>
                <div className="flex flex-wrap gap-8">
                    <Button 
                    variant="primary" 
                    type="button" 
                    size="md" 
                    onClick={handleSubmit} 
                    disabled={loading}
                    >
                    {loading ? (
                        <Spinner />
                    ) : (
                        <img 
                        src={saveIcon} 
                        alt="Save" 
                        className="w-5 h-5" 
                        />
                    )}
                    {loading ? `${'Saving...'}` : 
                                `${'Save'}`} 
                    </Button>
                </div>
                </FormContent>
            </FormContainer>
            {snackbarMessage && (
                <CustomizedSnackbars 
                    message={snackbarMessage}
                    type={snackbarType}
                    key={snackbarKey}
                />
            )}
        </SectionWrapper>
    )
}

export default ProfileSection;