import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PostDeletionForm from 'components/Forms/PostDeletionForm';
import CopywriterPost from 'contexts/CopywriterPost';



const CopywriterPostsSection = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [posts, setPosts] = useState<CopywriterPost[]>([]);

    const fetchFiles = async () => {
        
        const accessToken = await getAccessTokenSilently();
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(`${backendUrl}/copywriter/get`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setPosts(data);
    }

    useEffect(() => {
        fetchFiles();
    }, []);



    return (
            <SectionWrapper title="Copywriter Posts" description="Here you can delete all the posts you have created via the copywriter tool. Note that these posts may include sensitive data provided by your uploaded files.">
            <FormContainer>
                <FormLabel label="Deletion" description="Delete posts you don't need anymore." />
                <FormContent>
                    <div className="flex flex-col gap-4 w-full">
                        {posts.length > 0 ? posts.map((post) => (
                            <PostDeletionForm key={post.uuid} uuid={post.uuid} created_at={post.created_at} input={post.input} output={post.output} settings={post.settings} />
                        )) : (
                            <div className="flex h-full">
                                <p className="text-gray-500">You don't have any posts saved with us.</p>
                            </div>
                        )}
                    </div>
                </FormContent>
            </FormContainer>
        </SectionWrapper>
    )
}

export default CopywriterPostsSection;