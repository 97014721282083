import React, { useState, useEffect } from 'react';
import HeaderWithBack from 'components/Common/HeaderWithBack';
import FilesSection from 'components/Deletion/FilesSection';
import CopywriterPostsSection from 'components/Deletion/CopywriterPostsSection';
import SentimentPostsSection from 'components/Deletion/SentimentPostsSection';



const DeletionPage: React.FC = () => {
  
  return (
    <>
      <HeaderWithBack title="Deletion" />
      <FilesSection />
      <CopywriterPostsSection />
      <SentimentPostsSection />
    </>
  );
};

export default DeletionPage;