import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Forms/Checkbox';
import chevronDown from 'assets/images/chevron-down.svg';
import chevronUp from 'assets/images/chevron-up.svg';
import { useAuth0, User } from '@auth0/auth0-react';
import Button from 'components/Forms/Button';
import Spinner from 'components/Forms/Spinner';
import saveIcon from 'assets/images/save.svg';
import CustomizedSnackbars from 'components/Forms/Snackbar';
interface UserFormProps {
  avatar: string;
  user: User;
}

const UserForm: React.FC<UserFormProps> = ({
  avatar,
  user,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [checkboxLabels, setCheckboxLabels] = useState<string[]>(["Cold Outreach", "Copywriting", "Sentiment Analysis"]);
  const [checkedStates, setCheckedStates] = useState<boolean[]>([user.preferences.modules.includes("LMG"), user.preferences.modules.includes("COPYWRITING"), user.preferences.modules.includes("SENTIMENT_ANALYSIS")]);
  const [userFormData, setUserFormData] = useState<User | null>(user);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);
  const moduleMapping: { [key: string]: string } = {
    "Cold Outreach": "LMG",
    "Copywriting": "COPYWRITING",
    "Sentiment Analysis": "SENTIMENT_ANALYSIS",
  };



  const onCheckboxChange = (index: number, checked: boolean) => {
    setCheckedStates(prev => {
        const newStates = [...prev];
        newStates[index] = checked;
        return newStates;
    });
    
    // Update company modules using the mapping
    if (userFormData) {
        const updatedModules = checkboxLabels
            .filter((_, idx) => idx === index ? checked : checkedStates[idx])
            .map(label => moduleMapping[label]);
        
        setUserFormData(prev => {
            const newUserFormData = {
                ...prev,
                preferences: {
                    modules: updatedModules,
                    llm_provider: userFormData?.preferences.llm_provider,
                    llm_model: userFormData?.preferences.llm_model
                }
            };
            return newUserFormData;
        });
        console.log(userFormData);
    }
};

const handleSubmit = async () => {
    setLoading(true);

    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(backend_api_url + `/users/update/${userFormData?.uuid}`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userFormData),
    });
    if (response.ok) {
    setSnackbarMessage("User updated");
    setSnackbarKey(prev => prev + 1);
    setSnackbarType('success');
    }
    setLoading(false);

}

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-6">
          <div className="flex overflow-hidden relative flex-col justify-center self-stretch my-auto w-10 aspect-square rounded-full">
            <img
              loading="lazy"
              src={avatar}
              alt={`${user.first_name + " " + user.last_name}'s avatar`}
              className="object-cover absolute inset-0 size-full"
            />
          </div>
          <div className="flex gap-2 text-sm">
            <span className="font-semibold leading-5 text-slate-700">
              {user.first_name + " " + user.last_name}
            </span>
            <span className="leading-5 text-slate-500">
              ({user.email})
            </span>
          </div>
        </div>
        <button
          className="flex overflow-hidden gap-2 justify-center items-center p-2 rounded-lg hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label={isExpanded ? "Collapse" : "Expand"}
        >
          <img
            loading="lazy"
            src={isExpanded ? chevronUp : chevronDown}
            alt={isExpanded ? "Collapse" : "Expand"}
            className="w-5 h-5"
          />
        </button>
      </div>
      
      {isExpanded && (
        <div className="flex items-start justify-between gap-4 p-4 border-t border-gray-200">
          <div className="flex flex-col gap-4">
            {checkboxLabels.map((label, index) => (
              <Checkbox
                key={index}
                label={label}
                isChecked={checkedStates[index]}
                onChange={(checked) => onCheckboxChange(index, checked)}
              />
            ))}
          </div>
          <div>
            <Button 
                variant="primary" 
                type="button" 
                size="md" 
                onClick={handleSubmit} 
                disabled={loading}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <img 
                    src={saveIcon} 
                    alt="Save" 
                    className="w-5 h-5" 
                    />
                )}
                {loading ? `${'Saving...'}` : 
                            `${'Save'}`} 
            </Button>
          </div>
        </div>
      )}
      {snackbarMessage && (
        <CustomizedSnackbars 
            message={snackbarMessage}
            type={snackbarType}
            key={snackbarKey}
        />
    )}
    </div>
  );
};

export default UserForm;
