import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FileForm from 'components/Forms/FileForm';


const FilesSection = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [files, setFiles] = useState<{name: string, id: string}[]>([]);

    const fetchFiles = async () => {
        
        const accessToken = await getAccessTokenSilently();
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(`${backendUrl}/files/get`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setFiles(data);
    }

    useEffect(() => {
        fetchFiles();
    }, []);

    const refreshFiles = () => {
        fetchFiles();
    }


    return (
            <SectionWrapper title="Files" description="Here you can delete all the files you have uploaded to the app. This includes all audio files you have recorded, which are currently all named 'blob'.">
            <FormContainer>
                <FormLabel label="Deletion" description="Delete files you don't need anymore." />
                <FormContent>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
                        {files.length > 0 ? files.map((file) => (
                            <FileForm key={file.id} name={file.name} id={file.id} onDelete={refreshFiles} />
                        )) : (
                            <div className="flex h-full">
                                <p className="text-gray-500">You don't have any files saved with us.</p>
                            </div>
                        )}
                    </div>
                </FormContent>
            </FormContainer>
        </SectionWrapper>
    )
}

export default FilesSection;