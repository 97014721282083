import React, { useState } from 'react';
import { useAuth0} from '@auth0/auth0-react';
import Spinner from 'components/Forms/Spinner';
import deleteIcon from 'assets/images/delete-grey.svg';
import fileIcon from 'assets/images/file-icons/doc.svg';
import placeholderIcon from 'assets/images/x-close.svg';
import audioIcon from 'assets/images/record-icon-violett.svg';
import editIcon from 'assets/images/edit-04.svg';
import CustomizedSnackbars from 'components/Forms/Snackbar';
import CopywriterPost from 'contexts/CopywriterPost';

const PostDeletionForm: React.FC<CopywriterPost> = ({
  uuid,
  input,
  output
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);


const handleDelete = async () => {
    setLoading(true);

    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(backend_api_url + `/copywriter/posts/${uuid}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
    });
    if (response.ok) {
    setSnackbarMessage("Post deleted");
    setSnackbarKey(prev => prev + 1);
    setSnackbarType('success');
    }
    setLoading(false);
    window.location.reload();
}

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-6">
          <div className="flex overflow-hidden relative flex-row gap-2 justify-center items-center self-stretch my-auto w-auto">
                {input.file && input.file.is_serialized ? (
                    <img src={fileIcon} alt="File" className="w-5 h-5" />
                ) : (
                    <img src={placeholderIcon} alt="Placeholder" className="w-5 h-5" />
                )}
                {input.inspiration && input.inspiration.audio_id ? (
                    <img src={audioIcon} alt="Audio" className="w-5 h-5" />
                ) : (
                    <img src={editIcon} alt="Edit" className="w-5 h-5" />
                )}
          </div>
          <div className="flex gap-2 text-sm">
            <span className="font-semibold leading-5 text-slate-700">
              {output.subject}
            </span>
          </div>
        </div>
        <button
          onClick={handleDelete}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          disabled={loading}
        >
          {loading ? (
            <Spinner />
          ) : (
            <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
          )}
        </button>
      </div>
      {snackbarMessage && (
        <CustomizedSnackbars 
            message={snackbarMessage}
            type={snackbarType}
            key={snackbarKey}
        />
    )}
    </div>
  );
};

export default PostDeletionForm;
