import Header from 'components/Common/Header';
import CompanySection from 'components/Admin/CompanySection';
import UserSection from 'components/Admin/UserSection';
const AdminPanel: React.FC = () => {

  return (
    <>
      <Header title="Admin Panel" />
      <CompanySection />
      <UserSection />
    </>
  );
};

export default AdminPanel;