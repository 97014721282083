import React from 'react';
import HeaderWithBack from 'components/Common/HeaderWithBack';
import PostsSection from 'components/History/CopywriterPostsSection';
import SentimentPostsSection from 'components/History/SentimentPostsSection';
interface HistoryPageProps {
  tool: string;
}


const HistoryPage: React.FC<HistoryPageProps> = ({ tool }) => {


  return (
    <>
      <HeaderWithBack title="History" />
      {tool === "COPYWRITING" && <PostsSection />}
      {tool === "SENTIMENT_ANALYSIS" && <SentimentPostsSection />}
    </>
  );
};

export default HistoryPage;