import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import TextField from 'components/Forms/TextField';
import { useEffect, useState } from 'react';
import Button from 'components/Forms/Button';
import Spinner from 'components/Forms/Spinner';
import saveIcon from 'assets/images/save.svg';
import { useAuth0 } from '@auth0/auth0-react';
import CustomizedSnackbars from 'components/Forms/Snackbar';
import AvatarUpload from 'components/Forms/AvatarUpload';
import Toggle from 'components/Forms/Toggle';
import Checkbox from 'components/Forms/Checkbox';
import defaultAvatar from 'assets/images/userAvatar.png';

interface Company {
  id: string;
  name: string;
  avatar: string;
  api_keys: { openai: string, anthropic: string };
  modules: string[];
  users: string[];
}

const moduleMapping: { [key: string]: string } = {
    "Cold Outreach": "LMG",
    "Copywriting": "COPYWRITING",       
    // Add more mappings as needed
};

const CompanySection = () => {
    const [companyFormData, setCompanyFormData] = useState<Company | null>(null);
    const [companyAvatarUrl, setCompanyAvatarUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info' | undefined>(undefined);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [checkboxLabels, setCheckboxLabels] = useState<string[]>(["Cold Outreach", "Copywriting"]);
    const [checkedStates, setCheckedStates] = useState<boolean[]>([false, false]);

    const onSelect = async (companyId: string) => {
        const company = companies.find(company => company.id === companyId);
        if (company) {
            setSelectedCompany(company);
            setCompanyFormData(company);
            setCheckedStates(checkboxLabels.map(label => 
                company.modules.includes(moduleMapping[label])
            ));
            const token = await getAccessTokenSilently();
            const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
            const response = await fetch(`${backend_api_url}/files/retrieve/${company.avatar}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
              const blob = await response.blob();
              // Konvertiere Blob zu Base64
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64data = reader.result as string;
                setCompanyAvatarUrl(base64data);
              };
              reader.readAsDataURL(blob);
        }
        if (companyId === "new") {
            setCompanyFormData({
                id: "new",
                name: "",
                avatar: "default.png",
                api_keys: { openai: "", anthropic: "" },
                modules: [],
                users: []
            });
            setCheckedStates(checkboxLabels.map(label => false));
            setCompanyAvatarUrl(defaultAvatar);
        }
    }

    const fetchCompanies = async () => {
        const accessToken = await getAccessTokenSilently();
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(`${backendUrl}/companies`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setCompanies(data);
    }

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (companies.length > 0 && !selectedCompany) {
            onSelect(companies[0].id);
        }
    }, [companies]);

    const handleAvatarUpload = async (file: File) => {
        const token = await getAccessTokenSilently();
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        
        const formData = new FormData();
        formData.append("file", file);
        
        const url = new URL(backend_api_url + '/files/upload');
        url.searchParams.append("container_name", "avatars");
        
        try {
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            
            if (!response.ok) {
                throw new Error(`Upload failed: ${await response.text()}`);
            }

            const file_id = await response.json();
            console.log("Upload successful, file_id:", file_id);
            
            // Setze je nach Dateityp den entsprechenden State
            setCompanyFormData(prev => prev ? {
                ...prev,
                avatar: file_id
            } : null);
            
            return file_id;
        } catch (error) {
            console.error("Upload error:", error);
            throw error;
        }
    };

    const onCheckboxChange = (index: number, checked: boolean) => {
        setCheckedStates(prev => {
            const newStates = [...prev];
            newStates[index] = checked;
            return newStates;
        });
        
        // Update company modules using the mapping
        if (companyFormData) {
            const updatedModules = checkboxLabels
                .filter((_, idx) => idx === index ? checked : checkedStates[idx])
                .map(label => moduleMapping[label]);
            
            setCompanyFormData({
                ...companyFormData,
                modules: updatedModules
            });
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (companyFormData?.id === "new") {
            const token = await getAccessTokenSilently();
            const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
            const response = await fetch(backend_api_url + `/companies/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(companyFormData)
            });
            if (!response.ok) {
                throw new Error(`Failed to create organization: ${response.statusText}`);
            }
            setSnackbarMessage("Company created");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('success');
            fetchCompanies();
            setLoading(false);
            return;
        }
        if (companyFormData?.name === '') {
            setSnackbarMessage("Please enter a name");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('error');
            setLoading(false);
            return;
        }
        const token = await getAccessTokenSilently();
        const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
        const response = await fetch(backend_api_url + `/companies/update/${companyFormData?.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(companyFormData),
        });
        if (response.ok) {
        setSnackbarMessage("Company updated");
        setSnackbarKey(prev => prev + 1);
        setSnackbarType('success');
        fetchCompanies();
        }
        setLoading(false);

    }

    return (
            <SectionWrapper title="Companies" description="Manage companies access to Forge.">
            <FormContainer>
                <FormLabel label="Selection" description="Select a company to edit or add a new company." />
                <FormContent>
                    <Toggle
                        options={companies.map((company) => ({ caption: company.name, value: company.id }))
                                .concat({ caption: "+ Add new", value: "new" })}
                        onSelect={onSelect}
                    />
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Avatar" description="The avatar of the company." />
                <FormContent>
                    <AvatarUpload 
                        onAvatarUpload={handleAvatarUpload}
                        avatarUrl={companyAvatarUrl}
                    />
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Name" description="Only letters and numbers allowed!" />
                <FormContent>
                    <TextField 
                        name="name" 
                        ariaLabel="Name" 
                        initialValue={companyFormData?.name || ''}
                        showmaxChars={false}
                        onChange={(value) => {
                            if (companyFormData) {
                                setCompanyFormData({ ...companyFormData, name: value });
                            }
                        }} 
                    />
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="API Keys" description="The API keys of the company." />
                <FormContent>
                    <div className="flex items-center gap-2">
                        <span className="min-w-[100px] font-medium">OpenAI:</span>
                        <TextField 
                            name="openai" 
                            ariaLabel="OpenAI API Key" 
                            initialValue={companyFormData?.api_keys['openai'] || ''}
                            showmaxChars={false}
                            onChange={(value) => {
                                if (companyFormData) {
                                    setCompanyFormData({
                                        ...companyFormData,
                                        api_keys: {
                                            ...companyFormData.api_keys,
                                            openai: value
                                        }
                                    });
                                }
                            }}
                        />
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="min-w-[100px] font-medium">Claude:</span>
                        <TextField 
                            name="anthropic " 
                            ariaLabel="Anthropic API Key" 
                            initialValue={companyFormData?.api_keys['anthropic'] || ''}
                            showmaxChars={false}
                            onChange={(value) => {
                                if (companyFormData) {
                                    setCompanyFormData({
                                        ...companyFormData,
                                        api_keys: {
                                            ...companyFormData.api_keys,
                                            anthropic: value
                                        }
                                    });
                                }
                            }}
                        />
                    </div>
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Modules" description="The modules of the company." />
                <FormContent>
                <div className="flex flex-wrap gap-4">
                    {checkboxLabels.map((label, index) => (
                    <Checkbox
                        key={index}
                        label={label}
                        isChecked={checkedStates[index]}
                        onChange={(checked) => onCheckboxChange(index, checked)}
                    />
                    ))}
                </div>
                </FormContent>
            </FormContainer>
            <FormContainer>
                <FormLabel label="Save" description="Save your changes." />
                <FormContent>
                <div className="flex flex-wrap gap-8">
                    <Button 
                    variant="primary" 
                    type="button" 
                    size="md" 
                    onClick={handleSubmit} 
                    disabled={loading}
                    >
                    {loading ? (
                        <Spinner />
                    ) : (
                        <img 
                        src={saveIcon} 
                        alt="Save" 
                        className="w-5 h-5" 
                        />
                    )}
                    {loading ? `${'Saving...'}` : 
                                `${'Save'}`} 
                    </Button>
                </div>
                </FormContent>
            </FormContainer>
            {snackbarMessage && (
                <CustomizedSnackbars 
                    message={snackbarMessage}
                    type={snackbarType}
                    key={snackbarKey}
                />
            )}
        </SectionWrapper>
    )
}

export default CompanySection;